<template>
  <Page mode="jacket">
    <h1>{{ labels.YourPersonalCitizenAccount }}</h1>
    <p v-html="labels.Introduction"></p>
    <br/>
    <p>
    {{ labels.SystemAccessError }}
    </p>
  </Page>
</template>

<script>
import Page from '@/structure/Page.vue'
import { vcm } from '@/mixins/vcm'
import { vvm } from '@/mixins/vvm'

export default {
  mixins: [vcm,vvm],
  name: 'SystemInfo',
  components: {
    Page
  },
  data() {
    return {
      labels: {
        YourPersonalCitizenAccount: this.get_translation('SignIn','YourPersonalCitizenAccount','label'),
        Introduction: this.get_translation('SignIn','Introduction','label'),        
        SystemInfoHeadline: this.get_translation('App','SystemInfoHeadline','label'),
        SystemAccessError: this.get_translation('App','SystemAccessError','label'),
      }
    };
  },
  mounted: function () {    
  }
}

</script>

<style scoped>

</style>

